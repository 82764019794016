import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';

import { DevicesService } from '../../services/devices.service';
import { Device } from '../../model/device';

import { GatewaysService } from '../../services/gateways.service';
import { Gateway } from '../../model/gateway';

import { RulesService } from '../../services/rules.service';
import { Rules } from '../../model/rules';

import { StrappingsService } from '../../services/strappings.service';
import { Strapping } from '../../model/strapping';

import { LocationsService } from '../../services/locations.service';
import { GroupsService } from '../../services/groups.service';

import { Location } from '../../model/location';
import { Group } from '../../model/group';
import { Permissions } from '../../model/permissions';

@Component({
	selector: 'app-device-edit',
	templateUrl: './device-edit.component.html',
	styleUrls: ['./device-edit.component.css'],
})
export class DeviceEditComponent implements OnInit, OnDestroy {
	public permissions: Permissions = new Permissions();
	public hasError: boolean;
	public message: string;
	public notificationModel: any = {};
	public model: any = {};
	public rulesModel: any = {};
	public strappingModel: any = [];
	public user_form: UntypedFormGroup;

	public alarmLabel: string = 'Distance';
	public linearUnit: string;

	public device: Device = new Device();
	public device_id: number = 0;
	public gateways: Observable<Gateway[]>;
	public groups: Observable<Group[]>;
	public locations: Observable<Location[]>;
	public locationsByGroupId: Location[] = [];
	public gatewaysByLocationId: Gateway[] = [];

	private strappings: Observable<Strapping[]>;
	private st: any;

	public defaultDisplay: any = [];
	public shapes: any = [];
	public channelTypes: any = [];
	public alarm_uom_code: any = [];
	public weight_uom_code: any = [];
	public volume_uom_code: any = [];
	public density_uom_code: any = [];
	public linear_uom_code: any = [];
	public strapping_unit: any = [];
	public strapping_label: string;
	public strappingSize: number = 0;
	public addr: any = [];
	public device$: any;
	public canUpdateSilo = false;
	sensorTypes = [];

	constructor(
		private activatedRoute: ActivatedRoute,
		private devicesService: DevicesService,
		private gatewaysService: GatewaysService,
		private rulesServices: RulesService,
		private groupsServices: GroupsService,
		private locationsServices: LocationsService,
		private strappingsServices: StrappingsService,
		private router: Router,
		private formbuilder: UntypedFormBuilder
	) {
		for (let i = 1; i <= 32; i++) {
			this.addr.push(i);
		}

		const codes = JSON.parse(localStorage.getItem('codes'));

		this.sensorTypes = codes.filter((x) => x.TYPE === 'sensorType');


		for (let x of codes) {
			if (x.TYPE.toLowerCase() === 'display prefs') {
				this.defaultDisplay.push(x);
			}

			if (x.TYPE.toLowerCase() === 'shapes') {
				this.shapes.push(x);
			}
			if (x.TYPE.toLowerCase() === 'alarm uom' && ['A', 'F', 'G', 'H'].indexOf(x.CODE.toUpperCase()) === -1) {
				//remove distance and ullage for alarm units
				this.alarm_uom_code.push(x);
			}
			if (x.TYPE.toLowerCase() === 'uom weight') {
				x.label = '';
				if (['A', 'B'].indexOf(x.CODE) > -1) {
					x.label = 'F';
				} else if (['C', 'D'].indexOf(x.CODE) > -1) {
					x.label = 'M';
				}
				this.weight_uom_code.push(x);
			}
			if (x.TYPE.toLowerCase() === 'uom volume') {
				x.label = '';
				if (['A', 'B', 'C'].indexOf(x.CODE) > -1) {
					x.label = 'F';
				} else if (['D', 'E', 'F', 'G'].indexOf(x.CODE) > -1) {
					x.label = 'M';
				}

				this.volume_uom_code.push(x);
			}
			if (x.TYPE.toLowerCase() === 'uom density' && x.DECODE.toLowerCase() !== 'gs/cm3') {
				this.density_uom_code.push(x);
			}
			if (x.TYPE.toLowerCase() === 'uom linear') {
				x.label = '';
				if (x.CODE == 'F') {
					x.label = 'English';
				} else if (x.CODE == 'M') {
					x.label = 'Metric';
				}
				this.linear_uom_code.push(x);
			}
			if (x.TYPE.toLowerCase() === 'strapping units' && (x.CODE.toUpperCase() === 'B' || x.CODE.toUpperCase() === 'C')) {
				this.strapping_unit.push(x);
			}

			if (x.TYPE.toLowerCase() === 'channeltype') {
				this.channelTypes.push(x);
			}
		}

		this.activatedRoute.params.subscribe((params: Params) => {
			this.device_id = params['id'];
		});
		this.canUpdateSilo = ['A', 'B', 'D'].filter((type) => this.getUserType() === type).length > 0;
	}

	ngOnInit() {
		this.gatewaysService.getGateways();
		this.gateways = this.gatewaysService.gateways;

		this.groupsServices.getGroups();
		this.groups = this.groupsServices.groups;

		this.locationsServices.getLocations();
		this.locations = this.locationsServices.locations;

		if (!this.permissions.hasSilosPermission()) {
			this.router.navigateByUrl('devices');
		}

		if (this.device_id != 0) {
			this.devicesService.getDeviceByIdAdmin(this.device_id);

			this.device$ = this.devicesService.deviceAdmin.subscribe((x) => {
				x.map((y) => {
					this.device = y;
					this.setDeviceModel(y);
					this.getStrappingLabel(y.strapping_type || 'A');
					this.setStrappingModel(y);
					// this.device$.unsubscribe();
				});
			});
		} else {
			if (!this.permissions.hasAddSilosPermission()) {
				this.router.navigateByUrl('devices');
			}
		}
	}

	setDeviceModel(d: Device) {
		try {
			this.getLocationByGroupID(d.group_id);
			this.getGatewayByLocationID(d.location_id);

			this.linearUnit = d.linear_uom || 'M';
			this.model.device_id = d.device_id;
			this.model.gateway_id = d.gateway_id;
			this.model.gateway_name = d.gateway_name;
			this.model.device_name = d.device_name;
			this.model.device_dia_name = d.device_dia_name;
			this.model.location_name = d.location_name;
			this.model.location_id = d.location_id;
			this.model.group_id = d.group_id;
			this.model.company_name = d.company_name;
			this.model.status_cde = d.status_cde;
			this.model.material = d.material;

			this.model.density = d.round2DecimalPt(d.density);
			this.model.density_uom = d.density_uom;
			this.model.linear_uom = d.linear_uom ? d.linear_uom.trim() : '';
			this.model.linear_type = d.linear_uom ? d.linear_uom.trim() : '';
			this.model.volume_uom = d.volume_uom;
			this.model.weight_uom = d.weight_uom;
			this.model.alarm_display = d.alarm_display;
			this.model.default_display = d.default_display;
			this.model.shape = d.shape ? d.shape.trim().toUpperCase() : '';
			this.model.height = d.round2DecimalPt(d.height); //( d.height && !isNaN(d.height) )? d.getHeight() : 0;
			this.model.length = d.round2DecimalPt(d.length); //( d.length && !isNaN(d.length) )? d.getLength() : 0;
			this.model.width = d.round2DecimalPt(d.width); //( d.width && !isNaN(d.width) )? d.getWidth() : 0;
			this.model.diameter = d.round2DecimalPt(d.diameter); //( d.diameter &&!isNaN(d.diameter))? d.getDiameter() : 0;
			this.model.outlet_diameter = d.round2DecimalPt(d.outlet_diameter); //( d.outlet_diameter && !isNaN(d.outlet_diameter) )? d.getOutlet_diameter() : 0;
			this.model.outlet_height = d.round2DecimalPt(d.outlet_height); //( d.outlet_height && !isNaN(d.outlet_height) )? d.getOutlet_height() : 0;
			this.model.outlet_length = d.round2DecimalPt(d.outlet_length); //( d.outlet_length && !isNaN(d.outlet_length) )? d.getOutlet_length() : 0;
			this.model.outlet_width = d.round2DecimalPt(d.outlet_width); //( d.outlet_width && !isNaN(d.outlet_width) )? d.getOutlet_width() : 0;
			this.model.outlet_half_length = d.round2DecimalPt(d.outlet_half_length); //( d.outlet_half_length && !isNaN(d.outlet_half_length) )? d.getOutlet_half_length() : 0;
			this.model.outlet_half_width = d.round2DecimalPt(d.outlet_half_width); //( d.outlet_half_width && !isNaN(d.outlet_half_width) )? d.getOutlet_half_width() : 0;
			this.model.strapping_type = d.strapping_type;
			this.model.sensor_type = d.sensor_type ? d.sensor_type.trim().toLowerCase() : '';
			this.model.type = d.type.trim().toLowerCase();
			this.model.sensor_address = d.sensor_address;
			this.model.dead_zone = d.dead_zone;

			for (let c of this.alarm_uom_code) {
				if (this.model.alarm_display && this.model.alarm_display.trim().toUpperCase() == c.CODE.trim().toUpperCase()) {
					this.alarmLabel = c.DECODE;
				}
			}

			if (this.model.sensor_address < 1 || this.model.sensor_address > 32) {
				this.model.sensor_address.valid = false;
			}
		} catch (e) {
			/*TO DO: angular emits undefined object until the subscription is resolved: figure out how to better handle this*/
			console.log(d);
			console.log(e);
		}
	}

	changeLinearUOM() {
		this.updateUnitMeasurement(this.model.linear_type);
		return false;
	}

	setStrappingModel(device: Device) {
		this.strappingsServices.getStrapping(device.device_id);
		this.strappings = this.strappingsServices.strappings;

		let _device = Object.create(this.device);
		_device.linear_uom = this.model.linear_uom;

		let len;
		this.strappings.subscribe((x) => {
			if (x.length < 3) {
				this.addStrapping();
				this.addStrapping();
			}
			this.strappingSize = this.strappingModel.length;
		});

		this.st = this.strappings.subscribe((z) => {
			this.strappingModel.splice(0);
			z.map((y) => {
				_device.setDistance(y.distance);
				this.strappingModel.push({
					distance: _device.getDistance(),
					strapping: this.device.round2DecimalPt(y.strapping),
					device_id: this.device_id,
				});

				this.strappingSize = this.strappingModel.length;
			});
		});
	}
	addStrapping(): boolean {
		this.strappingModel.push({ distance: 0, strapping: 0, device_id: this.device_id });
		this.strappingSize = this.strappingModel.length;
		return false;
	}

	removeStrapping(i) {
		this.strappingModel.splice(i, 1);
		this.strappingSize = this.strappingModel.length;
	}

	getStrappingLabel(type) {
		let x: Observable<any> = of(this.strapping_unit);
		let obs$ = x.subscribe((y) => y.filter((d) => d.CODE == type).map((a) => (this.strapping_label = a.DECODE)));
		if (obs$) {
			obs$.unsubscribe();
		}
	}

	updateUnitMeasurement(unit) {
		unit = unit.toUpperCase().trim();
		let d = this.device;
		this.model.volume_uom = d.volume_uom;
		this.model.weight_uom = d.weight_uom;

		if (unit === 'F') {
			this.model.linear_uom = 'F';
			this.model.density_uom = 'A';
			if (['A', 'B', 'C'].indexOf(d.volume_uom) === -1) {
				this.model.volume_uom = 'A';
			}

			if (['A', 'B'].indexOf(d.weight_uom) === -1) {
				this.model.weight_uom = 'A';
			}
		} else if (unit === 'M') {
			this.model.linear_uom = 'M';
			this.model.density_uom = 'B';
			if (['D', 'E', 'F', 'G'].indexOf(d.volume_uom) === -1) {
				this.model.volume_uom = 'D';
			}
			if (['C', 'D'].indexOf(d.weight_uom) === -1) {
				this.model.weight_uom = 'C';
			}
		}
	}

	submitDevice(e) {
		// try{
		this.hasError = false;
		this.message = '';

		if (!this.canUpdateSilo) {
			this.hasError = true;
			this.message = 'Sorry! You do not have permission to edit a device.';
			return false;
		}
		this.model.type = this.model.sensor_type.split('_')[0];

		let copyModel = Object.assign({}, this.model);
		if (this.model.linear_uom != 'M') {
			copyModel.height = this.convertToMeters(this.model.height);
			copyModel.height = this.convertToMeters(this.model.height);
			copyModel.length = this.convertToMeters(this.model.length);
			copyModel.width = this.convertToMeters(this.model.width);
			copyModel.diameter = this.convertToMeters(this.model.diameter);
			copyModel.outlet_diameter = this.convertToMeters(this.model.outlet_diameter);
			copyModel.outlet_height = this.convertToMeters(this.model.outlet_height);
			copyModel.outlet_length = this.convertToMeters(this.model.outlet_length);
			copyModel.outlet_width = this.convertToMeters(this.model.outlet_width);
			copyModel.outlet_half_length = this.convertToMeters(this.model.outlet_half_length);
			copyModel.outlet_half_width = this.convertToMeters(this.model.outlet_half_width);
			copyModel.density = this.convertToKg_meter_cube(this.model.density);
		}

		if (this.model.shape === 'E') {
			let copyStrap = JSON.parse(JSON.stringify(this.strappingModel));			

			for (let i = 0; i < copyStrap.length; i++) {
				/*convert to meters before saving*/
				let a = 0;
				if (this.model.linear_uom != 'M') {
					a = this.convertToMeters(copyStrap[i].distance);
				} else {
					a = copyStrap[i].distance;
				}
				copyStrap[i].distance = a;

				let dd = new Device();

				console.log("this.model.strapping_type", this.model.strapping_type);
				if (this.model.strapping_type.toUpperCase() == 'A') {
					dd.volume_uom = this.model.linear_uom;
					copyStrap[i].strapping = copyStrap[i].strapping / dd.getMultiplier();
				} else if (this.model.strapping_type.toUpperCase() == 'B') {
					dd.volume_uom = this.model.volume_uom;
					copyStrap[i].strapping = copyStrap[i].strapping / dd.getVolumeMultiplier();
				} else if (this.model.strapping_type.toUpperCase() == 'C') {
					dd.weight_uom = this.model.weight_uom;
					copyStrap[i].strapping = copyStrap[i].strapping / dd.getWeightMultiplier();
				}
			}

			this.strappingsServices.saveStrapping(copyStrap, this.device_id);

			/*convert back to the display unit*/
			// let tempDevice = this.device;
			// for(let i = 0; i < this.strappingModel.length; i++){
			// 	tempDevice.setDistance(this.strappingModel[i].distance);
			// 	this.strappingModel[i].distance = tempDevice.getDistance();
			// }
		}

		this.devicesService.saveDevice(copyModel, this.device_id);

		this.devicesService.hasError.subscribe((x) => (this.hasError = x));
		this.devicesService.message.subscribe((x) => {
			this.message = x;
			this.scrollTop();
		});
		this.devicesService.device_id.subscribe((x) => {
			this.model.device_id = x;
			this.device_id = x;
		});

		// 	}catch(e){
		// 		console.log(e);
		// }
	}

	convertToMeters(value) {
		return value / 3.28084 || value;
	}

	convertToKg_meter_cube(value) {
		return value * 16.0185;
	}

	fakeDelete(e) {
		return false;
	}

	deleteDevice(d_id) {
		let body = { device_id: d_id };
		this.devicesService.deleteDevice(body);
		return false;
	}

	getLocationByGroupID(id) {
		this.locationsByGroupId.splice(0);
		this.getGatewayByGroupID(id);
		this.locationsServices.locations.subscribe((x) =>
			x
				.filter((y) => y.group_id == id)
				.filter((f) => this.locationsByGroupId.map((l) => l.location_id).indexOf(f.location_id) === -1)
				.map((z) => this.locationsByGroupId.push(z))
		);
	}

	getGatewayByLocationID(id) {
		this.gatewaysByLocationId.splice(0);
		this.gateways.subscribe((x) => x.filter((y) => y.location_id == id).map((z) => this.gatewaysByLocationId.push(z)));
	}

	getGatewayByGroupID(id) {
		this.gatewaysByLocationId.splice(0);
		this.gatewaysService.gateways.subscribe((x) => x.filter((y) => y.group_id == id).map((z) => this.gatewaysByLocationId.push(z)));
	}

	ngOnDestroy() {
		// if(this.st){
		// 	this.st.unsubscribe();
		// }
		if (this.device$) {
			this.device$.unsubscribe();
		}
	}

	getUserType() {
		return this.permissions.userType;
	}
	scrollTop() {
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}
}
