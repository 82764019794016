import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl, ReactiveFormsModule, FormArray } from '@angular/forms';

import { RulesService } from '../../services/rules.service';
import { Rules } from '../../model/rules';
import { DevicesService } from '../../services/devices.service';
import { Device } from '../../model/device';
import { Permissions } from '../../model/permissions';

@Component({
	selector: 'app-rules-edit',
	templateUrl: './rules-edit.component.html',
	styleUrls: ['./rules-edit.component.css'],
})
export class RulesEditComponent implements OnInit {
	public permissions: Permissions = new Permissions();
	public device: Device;
	public device_id: number;
	public deviceChannel_id: number;
	public alarm_uom_code: any = [];
	public alarm_uom: string;
	public alarmLabel: string;
	public llrules: Observable<Rules>;
	public lrules: Observable<Rules>;
	public hrules: Observable<Rules>;
	public hhrules: Observable<Rules>;
	public statusrules: Observable<Rules>;
	public hasError: boolean;
	public message: string;
	device_name = '';
	canUpdateAlarm = false;

	llThreshold = new UntypedFormControl('');
	// llDeadband 		= new FormControl('');
	llDelay = new UntypedFormControl({ value: 0, disabled: true });
	llMsg = new UntypedFormControl('');
	lThreshold = new UntypedFormControl('');
	lDeadband = new UntypedFormControl('');
	lDelay = new UntypedFormControl({ value: 0, disabled: true });
	lMsg = new UntypedFormControl('');
	hThreshold = new UntypedFormControl('');
	// hDeadband 		= new FormControl('');
	hDelay = new UntypedFormControl({ value: 0, disabled: true });
	hMsg = new UntypedFormControl('');
	hhThreshold = new UntypedFormControl('');
	// hhDeadband 		= new FormControl('');
	hhDelay = new UntypedFormControl({ value: 0, disabled: true });
	hhMsg = new UntypedFormControl('');
	status_threshold = new UntypedFormControl({ value: '', disabled: true });
	// status_deadband	= new FormControl({value: '', disabled: true});
	status_delay = new UntypedFormControl(0);
	status_msg = new UntypedFormControl({ value: '', disabled: true });
	notifyonce = new UntypedFormControl(false);
	llnotifyonce = new UntypedFormControl(false);
	lnotifyonce = new UntypedFormControl(false);
	hnotifyonce = new UntypedFormControl(false);
	hhnotifyonce = new UntypedFormControl(false);
	statusnotifyonce = new UntypedFormControl(false);

	rulesForm: UntypedFormGroup = this.builder.group({
		llThreshold: this.llThreshold,
		// , llDeadband: 		this.llDeadband
		llDelay: this.llDelay,
		llMsg: this.llMsg,
		lThreshold: this.lThreshold,
		// , lDeadband: 		this.lDeadband
		lDelay: this.lDelay,
		lMsg: this.lMsg,
		hThreshold: this.hThreshold,
		// , hDeadband: 		this.hDeadband
		hDelay: this.hDelay,
		hMsg: this.hMsg,
		hhThreshold: this.hhThreshold,
		// , hhDeadband: 		this.hhDeadband
		hhDelay: this.hhDelay,
		hhMsg: this.hhMsg,
		status_threshold: this.status_threshold,
		// , status_deadband:	this.status_deadband
		status_delay: this.status_delay,
		status_msg: this.status_msg,
		notifyonce: this.notifyonce,
		llnotifyonce: this.llnotifyonce,
		lnotifyonce: this.lnotifyonce,
		hnotifyonce: this.hnotifyonce,
		hhnotifyonce: this.hhnotifyonce,
		statusnotifyonce: this.statusnotifyonce,
	});

	constructor(
		private activatedRoute: ActivatedRoute,
		private rulesServices: RulesService,
		private devicesService: DevicesService,
		private builder: UntypedFormBuilder
	) {
		const codes = JSON.parse(localStorage.getItem('codes'));

		for (let x of codes) {
			if (x.TYPE.toLowerCase() === 'alarm uom') {
				this.alarm_uom_code.push(x);
			}
		}
		this.canUpdateAlarm = ['A', 'B', 'D'].filter((type) => this.getUserType() === type).length > 0;
	}

	ngOnInit() {
		this.activatedRoute.params.subscribe((params: Params) => {
			this.device_id = params['id'];

			this.devicesService.getDeviceByIdAdmin(this.device_id);
			this.devicesService.deviceAdmin.subscribe((x) => {
				x.filter((y) => y.device_id == this.device_id).map((d) => {
					this.device = d;
					this.device_name = d.device_name;
					for (let c of this.alarm_uom_code) {
						if (d.alarm_display && d.alarm_display.trim().toUpperCase() == c.CODE.trim().toUpperCase()) {
							this.alarmLabel = c.DECODE;
						}
					}
				});
				
				if(this.device) {
					this.rulesServices.getRules(this.device_id);
					this.processRules();
				}
			});
		});

		
		// let rules = this.rulesServices.rules;
	}
	
	processRules () {
		this.rulesServices.rules.subscribe((x) => {
			x.filter((f) => f.type.trim().toUpperCase() !== 'STATUS').map((y) => {
				this.deviceChannel_id = y.deviceChannel_id;
				this.alarm_uom = y.alarm_uom;
			});
		});
		let notify = 0;
		this.rulesServices.rules.subscribe((x) => {
			x.map((y) => {
				let temp;
				let tmp = (y.threshold_raw).toString().trim();

				if (y.notifyonce == 1) {
					notify = 1;
				}

				if (y.type.trim().toUpperCase() == 'LL') {
					// temp = this.getConvertedThreshold( y.threshold );
					y.threshold = tmp.length == 0 ? y.threshold_raw : this.device.round2DecimalPt(y.threshold_raw);
					this.llThreshold = new UntypedFormControl(y.threshold, [Validators.min(0), Validators.max(this.maxThreshold)]);
					// this.llDeadband = new FormControl(y.deadband);
					this.llDelay = new UntypedFormControl(y.delay || 0);
					this.llMsg = new UntypedFormControl(y.msg);
				} else if (y.type.trim().toUpperCase() == 'L') {
					// temp = this.getConvertedThreshold( y.threshold );
					y.threshold = tmp.length == 0 ? y.threshold_raw : this.device.round2DecimalPt(y.threshold_raw);
					this.lThreshold = new UntypedFormControl(y.threshold, [Validators.min(0), Validators.max(this.maxThreshold)]);
					this.lDeadband = new UntypedFormControl(y.deadband);
					this.lDelay = new UntypedFormControl(y.delay || 0);
					this.lMsg = new UntypedFormControl(y.msg);
				} else if (y.type.trim().toUpperCase() == 'H') {
					// temp = this.getConvertedThreshold( y.threshold );
					y.threshold = tmp.length == 0 ? y.threshold_raw : this.device.round2DecimalPt(y.threshold_raw);
					this.hThreshold = new UntypedFormControl(y.threshold, [Validators.min(0), Validators.max(this.maxThreshold)]);
					// this.hDeadband 	= new FormControl(y.deadband);
					this.hDelay = new UntypedFormControl(y.delay || 0);
					this.hMsg = new UntypedFormControl(y.msg);
				} else if (y.type.trim().toUpperCase() == 'HH') {
					// temp = this.getConvertedThreshold( y.threshold );
					y.threshold = tmp.length == 0 ? y.threshold_raw : this.device.round2DecimalPt(y.threshold_raw);
					this.hhThreshold = new UntypedFormControl(y.threshold, [Validators.min(0), Validators.max(this.maxThreshold)]);
					// this.hhDeadband = new FormControl(y.deadband);
					this.hhDelay = new UntypedFormControl(y.delay || 0);
					this.hhMsg = new UntypedFormControl(y.msg);
				} else if (y.type.trim().toUpperCase() == 'STATUS') {
					this.status_threshold = new UntypedFormControl({ value: '', disabled: true });
					// this.status_deadband = new FormControl({value: '', disabled: true});
					this.status_delay = new UntypedFormControl(y.delay || 0);
					this.status_msg = new UntypedFormControl({ value: '', disabled: true });
				}
			});

			this.notifyonce = new UntypedFormControl(notify);

			this.rulesForm = this.builder.group({
				llThreshold: this.llThreshold,
				// , llDeadband: 		this.llDeadband
				llDelay: this.llDelay,
				llMsg: this.llMsg,
				lThreshold: this.lThreshold,
				// , lDeadband: 		this.lDeadband
				lDelay: this.lDelay,
				lMsg: this.lMsg,
				hThreshold: this.hThreshold,
				// , hDeadband: 		this.hDeadband
				hDelay: this.hDelay,
				hMsg: this.hMsg,
				hhThreshold: this.hhThreshold,
				// , hhDeadband: 		this.hhDeadband
				hhDelay: this.hhDelay,
				hhMsg: this.hhMsg,
				status_threshold: this.status_threshold,
				// , status_deadband: 	this.status_deadband
				status_delay: this.status_delay,
				status_msg: this.status_msg,
				notifyonce: this.notifyonce,
			});
		});
	}
	
	get maxThreshold () {
		switch (this.alarmLabel) {
			case 'Volume':
				return this.device.max_volume;
			case 'Weight':
				// return (this.device.max_volume * this.device.getVolumeMultiplier() ) * this.device.density;
				return this._getMaxWeight();
			case 'Percent':
				return 100;
			default:
				return this.device.height;
		}
	}
	
	private _getMaxWeight () {
		const max_vol = this.device.max_volume / this.device.getVolumeMultiplier();
		const density = this.device.density * this.device.getDensityMultiplier();
		
		return max_vol * density * this.device.getWeightMultiplier();
	}
	
	get thresholdError (): boolean {
		return this.llThreshold.invalid || this.lThreshold.invalid || this.hThreshold.invalid || this.hhThreshold.invalid;
	}

	submitRules() {
		this.hasError = false;
		this.message = '';

		// if(!this.canUpdateAlarm){
		// 	this.hasError = true;
		// 	this.message = 'Sorry! You do not have permission to edit alarm rules.';
		// 	return false;
		// }

		let body = { ll: {}, l: {}, h: {}, hh: {}, status: {} };

		body.ll = {
			threshold_raw: this.llThreshold.value,
			threshold: this.convertToDistance(this.llThreshold.value),
			// , deadband: 		this.llDeadband.value
			delay: this.llDelay.value,
			msg: this.llMsg.value,
			device_id: this.device_id,
			deviceChannel_id: this.deviceChannel_id,
			type: 'LL',
			notifyonce: this.notifyonce.value,
		};
		body.l = {
			threshold_raw: this.lThreshold.value,
			threshold: this.convertToDistance(this.lThreshold.value),
			// , deadband: 		this.lDeadband.value
			delay: this.lDelay.value,
			msg: this.lMsg.value,
			device_id: this.device_id,
			deviceChannel_id: this.deviceChannel_id,
			type: 'L',
			notifyonce: this.notifyonce.value,
		};
		body.h = {
			threshold_raw: this.hThreshold.value,
			threshold: this.convertToDistance(this.hThreshold.value),
			// , deadband: 		this.hDeadband.value
			delay: this.hDelay.value,
			msg: this.hMsg.value,
			device_id: this.device_id,
			deviceChannel_id: this.deviceChannel_id,
			type: 'H',
			notifyonce: this.notifyonce.value,
		};
		body.hh = {
			threshold_raw: this.hhThreshold.value,
			threshold: this.convertToDistance(this.hhThreshold.value),
			// , deadband: 		this.hhDeadband.value
			delay: this.hhDelay.value,
			msg: this.hhMsg.value,
			device_id: this.device_id,
			deviceChannel_id: this.deviceChannel_id,
			type: 'HH',
			notifyonce: this.notifyonce.value,
		};
		body.status = {
			threshold: '',
			// , deadband: 		this.status_deadband.value
			delay: this.status_delay.value,
			msg: this.status_msg.value,
			device_id: this.device_id,
			deviceChannel_id: null,
			type: 'STATUS',
			notifyonce: this.notifyonce.value,
		};
		this.rulesServices.saveRules(body, 'saveAlarmRules', this.device_id);

		this.rulesServices.hasError.subscribe((x) => (this.hasError = x));
		this.rulesServices.message.subscribe((x) => (this.message = x));
	}

	convertToDistance(val) {
		let ullage: Device;
		ullage = Object.create(this.device);
		ullage.setDistance(0);


		let max_volume = ullage.getVolume();
		let max_weight = ullage.getWeight();
		let max_percent = 100;

		let _device: Device;
		// _device = Object.create( this.device );

		let convertedValue;

		if (!this.device || (!val && val !== 0) || (this.device.alarm_display && this.device.alarm_display.toUpperCase() == 'A') || isNaN(val)) {
			return val;
		}
		if (this.device && this.device.alarm_display && this.device.alarm_display.trim().toUpperCase() == 'B') {
			convertedValue = this.device.convertFromLevel(val);
		} else if (this.device && this.device.alarm_display && this.device.alarm_display.trim().toUpperCase() == 'C') {
			// convertedValue = _device.convertFromVolume( val );
			convertedValue = val / this.device.getVolumeMultiplier();
		} else if (this.device && this.device.alarm_display && this.device.alarm_display.trim().toUpperCase() == 'D') {
			// convertedValue = _device.convertFromWeight( val );
			convertedValue = val / this.device.getWeightMultiplier();
		} else if (this.device && this.device.alarm_display && this.device.alarm_display.trim().toUpperCase() == 'E') {
			// convertedValue = _device.convertFromPercent( val );
			convertedValue = this.device.getVolumeFromPercent(val) / this.device.getVolumeMultiplier();
		}

		return convertedValue;
	}

	getUserType() {
		return this.permissions.userType;
	}
}
