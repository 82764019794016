import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import 'rxjs/add/operator/share';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../model/user';
import { UtilService } from './util.service';

@Injectable()
export class UsersService {
	private usersUrl = environment.apiurl + '/location/getUsers';
	private recoverPass = environment.apiurl + '/location/recoverPassword';

	public hasError: Subject<boolean> = new Subject<boolean>();
	public message: Subject<string> = new Subject<string>();
	public user_id: Subject<number> = new Subject<number>();
	private _uuid: Subject<string> = new Subject<string>();
	public uuid: Observable<string> = this._uuid.asObservable();

	private _users: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
	public users: Observable<User[]> = this._users.asObservable();

	private _user: Subject<User[]> = new Subject<User[]>();
	public user: Observable<User[]> = this._user.asObservable();

	constructor(private util: UtilService, private router: Router) {}

	/*public getUsers(model): void {
		let page 			= model.page || 1;
		let recordsPerPage 	= model.recordsPerPage || 1000000;

		this.util._requestData( this.usersUrl + '&page=' + page + '&recordsPerPage=' + recordsPerPage)
			.map(ret=>ret.qry)
			.subscribe( gJSON =>{
									const usr = gJSON
									.map( jsonobj => User.fromJSON( jsonobj ));
									this._users.next( usr );
								})
	}*/
	getUsers(model): Observable<User[]> {
		const page = model.page || 1;
		const recordsPerPage = model.recordsPerPage || 1000000;

		const payload: any = {
			page,
			recordsPerPage
		}

		if(model.status_cde) payload.status_cde = model.status_cde;
		if(model.admin) payload.admin = model.admin;

		// let url = this.usersUrl + '&status_cde=' + status_cde + '&page=' + page + '&admin=' + admin + '&recordsPerPage=' + recordsPerPage;
		return this.util
			._getData(this.usersUrl, payload)
			.pipe(map((res) => {
				const users = res.data.map((user) => User.fromJSON(user));
				return users;
			}));
	}

	public getUser(user_id: any): void {
		const payload: any = {
			user_id,
			status_cde: 'all'
		}

		this.util
			._getData(this.usersUrl, payload)
			.subscribe((res) => {
				const users: any = res.data.map((user) => User.fromJSON(user));
				this._user.next(users);
			});
	}

	public getUserObs(user_id: number): Observable<User> {
		const payload: any = {
			user_id,
			status_cde: 'all'
		}
		return (
			this.util
				._getData(this.usersUrl, payload)
				// .map((ret) => ret.qry)
				.pipe(map((ret) => {
					return ret.data.map((jsonobj) => User.fromJSON(jsonobj));
					// this._user.next(usr);
				}))
		);
	}

	public recoverPassword(uuid: string): void {
		this.util
			._requestData(this.recoverPass + '?uuid=' + uuid)
			.pipe(map((ret) => ret.data))
			.subscribe(
				(gJSON) => {
					gJSON = gJSON.qry;
					// const usr = gJSON.map((jsonobj) => User.fromJSON(jsonobj));
					const usr = [User.fromJSON(gJSON)];
					this._user.next(usr);
				},
				(err) => console.log(err),
				() => 'done revover'
			);
	}

	public saveUser(body, user_id) {
		let url = environment.apiurl + '/location/saveUser';
		this.util
			._postData(url, body)
			.subscribe(
				(res) => {
					const user_id = res.data.user_id;

					this.user_id.next(user_id);
					if ((user_id == '' || user_id == 0) && res.user_name_exist.length > 0) {
						this.hasError.next(true);
						this.message.next('The Login Name is taken. Please change the Login Name and try again.');
					} else {
						console.log(res);
						this.hasError.next(false);
						this.message.next('User information is saved successfully.');
						// this.router.navigateByUrl('/administration/user/edit/' + res.user_id);
					}
				},
				(err) => {
					console.log('Error saving User');
					const msg = err.message ? err.message : 'There was an error saving user information. Please try again.';
					this.hasError.next(true);
					this.message.next(msg);
				},
				() => {
					console.log('saving User completed');
				}
			);
	}

	public deleteUser(body) {
		// console.log('boddlasdlkasdlkasdl;kdsfl;kds;lkdas', JSON.stringify( body ) );
		let url = environment.apiurl + '/location/deleteUser';
		this.util
			._postData(url, body)
			.pipe(map((ret) => ret.data))
			.subscribe(
				(res) => {
					console.log(res);
					this.hasError.next(false);
					this.message.next('User has been deleted successfully.');
				},
				(err) => {
					console.log('Error deleting User');
					const msg = err.message ? err.message : 'There was an error deleting this user. Please try again.';
					this.hasError.next(true);
					this.message.next(err.message);
				},
				() => {
					console.log('Deleting User completed');
				}
			);
	}
	public saveUserLocations(body, user_id) {
		let url = environment.apiurl + '/location/saveUserLocations';
		this.util
			._postData(url, body)
			.pipe(map((ret) => ret.data))
			.subscribe(
				() => this.router.navigateByUrl('/administration/user/edit/' + user_id),
				() => {
					console.log('Error saving Device');
				},
				() => console.log('saving Device completed')
			);
	}

	public updatePassword(body) {
		let url = environment.apiurl + '/location/resetPassword';
		this.util
			._postData(url, body)
			.subscribe(
				() => this.router.navigateByUrl('/login'),
				(err) => {
					console.log('Error saving User');
					this.hasError.next(true);
					this.message.next(err.message);
					console.log('error saving password', err);
				},
				() => console.log('Pasword Reset successful')
			);
	}

	public savePassword(body) {
		this.message.next('');
		let url = environment.apiurl + '/location/savePassword';
		this.util
			._postData(url, body)
			.pipe(map((ret) => ret.data))
			.subscribe(
				() => {
					this.hasError.next(false);
					this.message.next('Password is saved successfully.');
				},
				(err) => {
					console.log('Error saving User');
					this.hasError.next(true);
					this.message.next(err.message);
					console.log('error saving password', err);
				},
				() => {
					console.log('saving User completed');
					this.hasError.next(false);
					this.message.next('User password is saved successfully.');
				}
			);
	}

	public requestResetPassword(body) {
		let url = environment.apiurl + '/location/requestResetPassword';
		this.util
			._postData(url, body)
			.pipe(map((ret) => ret.data))
			.subscribe(
				(res) => {
					this.hasError.next(false);
					this.message.next(
						'If your username and email combination match a user in our system, you will be emailed a link to reset your password. Thanks.'
					);
				},
				() => {
					console.log('Error resseting');
					this.hasError.next(true);
					this.message.next('There was an error resetting your password. Please try again.');
				},
				() => {
					console.log('saving psd completed');
				}
			);
	}

	public resetPasswordAdmin(body) {
		let url = environment.apiurl + '/location/resetPasswordAdmin';
		this.util
			._postData(url, body)
			.pipe(map((ret) => ret.data))
			.subscribe(
				(res) => {
					this.hasError.next(false);
					this.message.next('An email is sent to ' + body.email + ' to reset the password.');
				},
				() => {
					console.log('Error resseting');
					this.hasError.next(true);
					this.message.next('There was an error resetting your password. Please try again.');
				},
				() => {
					console.log('saving psd completed');
				}
			);
	}

	public generateUserReport(body: any = {}): Observable<any> {
		const url = environment.apiurl + '/location/userReport';

		return this.util._postData(url, {}).pipe(map((ret) => ret.data));
	}

	public queueNotification(body: any): Observable<any> {
		// console.log({ body });
		const url = environment.apiurl + '/location/queueSystemNotification';

		return this.util._postData(url, body);
	}

	public getCode(type, temp_id = ''): Observable<any> {
		
		const payload: any = {
			type
		};
		
		if(temp_id) payload.temp_two_factor_auth_id = temp_id;

		return this.util._getData(`${environment.apiurl}/location/getVerificationCode`, payload).map((ret) => ret.data);
	}

	public saveAuthType(body: any): Observable<any> {
		// console.log({ body });
		let url = environment.apiurl + '/location/save2FAType';
		if (body.temp_two_factor_auth_id && body.temp_two_factor_auth_id.length > 0) {
			url = environment.apiurl + '/location/method=saveTemp2FAType';
		}

		return this.util._postData(url, body).map((ret) => ret.data);
	}

	public resendWelcomeEmail(id): Observable<any> {
		const url = environment.apiurl + '/location/sendWelcomeEmail?user_ids=' + id;

		return this.util._requestData(url);
	}
}
