import { Component, OnInit, ElementRef, EventEmitter, Output, OnDestroy, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, Router, Params } from '@angular/router';

import { DevicesService } from '../services/devices.service';
import { LocationsService } from '../services/locations.service';
import { GroupsService } from '../services/groups.service';

import { Device } from '../model/device';
import { Location } from '../model/location';
import { Group } from '../model/group';
import { Distance } from '../model/distance';
import { ClickOutside } from '../click-outside.directive';
import * as $ from 'jquery';

import { TableViewComponent } from './table-view/table-view.component';
import { Permissions } from '../model/permissions';

export interface Filter {
	searchString: string;
	groupID: number;
	locationID: number;
	material: string;
}
@Component({
	selector: 'app-device-list',
	templateUrl: './device-list.component.html',
	styleUrls: ['./device-list.component.css'],
})
export class DeviceListComponent implements OnInit, OnDestroy {
	permissions = new Permissions();
	@ViewChild(TableViewComponent) tableViewComponent: TableViewComponent;
	componentDestroyed$: Subject<boolean> = new Subject();

	model: any = {
		searchString: '',
		groupID: '',
		locationID: '',
		deviceID: '',
		hasAlert: '',
		page: 1,
		recordsPerPage: 10,
		orderBy: 'l.name, d.name',
		orderDirection: 'ASC',
		groupFilter: '',
		locationFilter: '',
	};

	public locations: Observable<Location[]>;
	public groups: Observable<Group[]>;
	// public materials: Observable<Device[]>;
	public devices: Observable<Device[]>;
	public deviceList: Observable<Device[]>;
	public series: Observable<Distance[]>;
	public isClassVisible: boolean = true;
	public isFilterVisible: boolean = false;
	public totalPages: number;
	public arr = Array;
	public interval: any;
	public showLocationFilter: boolean = false;
	public showGroupFilter: boolean = false;
	public showDeviceFilter: boolean = false;
	public selectedLocations: any = [];
	public selectedGroups: any = [];
	public selectedDevices: any = [];
	public filteredLocations: any = [];
	public listOfdevice: any = [];
	public isLoading: boolean = true;
	// public filteredMaterials:any = [];
	measureAllDevices = [];

	constructor(
		private devicesService: DevicesService,
		private locationsService: LocationsService,
		private groupsService: GroupsService,
		private activedRoutes: ActivatedRoute,
		private router: Router,
		private elRef: ElementRef
	) {
		this.locations = locationsService.locations;
		this.groups = groupsService.groups;
		
		
		// this.materials 	= devicesService.materials;
		this.devices = devicesService.devices;

		// devicesService.isClassVisible.subscribe(x=> this.isClassVisible = x);
		this.devices
			.subscribe((x) => {
				x.map((y) => {
					this.isLoading = false;
					this.totalPages = Math.ceil(y.total_record / this.model.recordsPerPage);
				});
			});
	}

	ngOnInit() {
		$('body').removeClass('bg-img');

		this.activedRoutes.params.subscribe((params: Params) => {
			let view = params['view'] || 'list';
			this.changeView(view.toLowerCase() == 'list');
		});

		this.groupsService.getGroups(null, null, null, 'a');
		this.locationsService.getLocations();
		// this.devicesService.getMaterials();

		this.deviceList = this.devicesService.getDevicesListByUser();

		// Clear Filted Location
		this.filteredLocations.splice(0);
		let sessionselectedGroups = sessionStorage.getItem('selectedGroups');
		if (sessionselectedGroups && JSON.parse(sessionselectedGroups).length > 0) {
			sessionselectedGroups = JSON.parse(sessionselectedGroups);
			Object.assign(this.selectedGroups, sessionselectedGroups);
			
			this.filteredLocations.splice(0);
			this.selectedGroups.forEach((v, i) => {
				this.locations.subscribe((a) => {
					a.filter((b) => b.group_id == v.id).map((b) => {
						return this.filteredLocations.push({ name: b.name, location_id: b.location_id })
					})
				});
			});
		} 
		// else if(this.permissions.hasManagerOrTechnicianRole()) {
		// 	this.groups.subscribe((x: any) => {
		// 		if(x.length == 1) {
		// 			const group = x[0];
		// 			this.addGroup(group.name, group.group_id, {target: {checked: true}});
		// 		}
		// 	});
		// } 
		else {
			this.locations.subscribe((a) => {
				this.filteredLocations.splice(0);
				a.map((b) => {
					this.filteredLocations.push({ name: b.name, location_id: b.location_id });
				});
			});
		}

		let sessionSelectedLocations = sessionStorage.getItem('selectedLocations');
		if (sessionSelectedLocations) {
			sessionSelectedLocations = JSON.parse(sessionSelectedLocations);
			Object.assign(this.selectedLocations, sessionSelectedLocations);
			this.addDeviceToList();
		} else {
			this.deviceList.subscribe((x) => x.map((a) => this.listOfdevice.push(a)));
		}

		let sessionSelectedDevices = sessionStorage.getItem('selectedDevices');
		if (sessionSelectedDevices) {
			sessionSelectedDevices = JSON.parse(sessionSelectedDevices);
			Object.assign(this.selectedDevices, sessionSelectedDevices);
		}

		this.model.searchString = sessionStorage.getItem('hasAlert') || '';
		this.model.hasAlert = sessionStorage.getItem('searchString') || '';
		this.model.recordsPerPage = sessionStorage.getItem('recordsPerPage') || 10;

		
		this.onSearch();
	}

	ngOnDestroy() {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
		clearInterval(this.interval);
		if (this.tableViewComponent?.interval) {
			clearInterval(this.tableViewComponent?.interval);
		}

		if (this.devicesService.interval) {
			this.devicesService.interval.unsubscribe();
		}
		
		this.devicesService._devices.next([]);
	}
	onSearch() {
		this.isFilterVisible = false;
		this.model.page = 1;

		if (this.tableViewComponent?.interval) {
			clearInterval(this.tableViewComponent?.interval);
		}
		let gid = [];
		this.selectedGroups.forEach((v, i) => {
			gid.push(v.id);
		});
		let lid = [];
		this.selectedLocations.forEach((v, i) => {
			lid.push(v.id);
		});

		let did = [];
		this.selectedDevices.forEach((v, i) => {
			did.push(v.id);
		});

		this.model.groupID = gid.toString();
		this.model.locationID = lid.toString();
		this.model.deviceID = did.toString();
		// material

		this.model.group;

		sessionStorage.setItem('selectedLocations', JSON.stringify(this.selectedLocations));
		sessionStorage.setItem('selectedGroups', JSON.stringify(this.selectedGroups));
		sessionStorage.setItem('selectedDevices', JSON.stringify(this.selectedDevices));
		sessionStorage.setItem('hasAlert', this.model.hasAlert);
		sessionStorage.setItem('searchString', this.model.searchString);
		sessionStorage.setItem('recordsPerPage', this.model.recordsPerPage);

		
		this.isLoading = true;
		this.devicesService.getFilteredDevices(this.model);
		this.initDeviceTimeStatusCheck();
	}

	showDeviceDetail(dID) {
		this.router.navigate(['/device/' + dID]);
	}

	changeView(value) {
		this.isClassVisible = value;
	}
	
	goToPage(page) {
		this.model.page = page;
		this.isLoading = true;
		this.devicesService.getFilteredDevices(this.model);
		this.initDeviceTimeStatusCheck();
	}
	
	initDeviceTimeStatusCheck () {
		setTimeout(() => {
			this.tableViewComponent?.getDeviceTimeStatus();
		}, 0);
	}

	addLocation(name, id, e) {
		let index = this.selectedLocations.map((x) => x.id).indexOf(id);
		if (e.target.checked && index < 0) {
			this.selectedLocations.push({ name: name, id: id });
			// this.getDevicesByLocation();
		} else {
			this.removeLocation(id);
		}
		this.addDeviceToList();
	}

	removeLocation(id) {
		let el = this.elRef.nativeElement.querySelector('.loc_' + id);
		if (el) el.checked = false;
		this.selectedLocations.forEach((item, index) => {
			if (id == item.id) {
				this.selectedLocations.splice(index, 1);
				// this.getDevicesByLocation();
			}
		});
	}

	isLocationSelected(id) {
		let index = this.selectedLocations.map((x) => x.id).indexOf(id);
		return index < 0 ? false : true;
	}

	addGroup(name, id, e) {
		let index = this.selectedGroups.map((x) => x.id).indexOf(id);
		if (e.target.checked && index < 0) {
			this.selectedGroups.push({ name: name, id: id });
			// this.getDevicesByLocation();
		} else {
			this.removeGroup(id);
		}

		this.selectedLocations.splice(0);
		this.filteredLocations.splice(0);

		if (this.selectedGroups.length === 0) {
			this.locations.subscribe((a) => a.map((b) => this.filteredLocations.push({ name: b.name, location_id: b.location_id })));
		}

		this.selectedGroups.forEach((v, i) => {
			this.locations.subscribe((a) =>
				a.filter((b) => b.group_id == v.id).map((b) => this.filteredLocations.push({ name: b.name, location_id: b.location_id }))
			);
		});
		
		
		// If only location is available, select it for manager or technician user role.
		if(this.filteredLocations.length === 1 && this.permissions.hasManagerOrTechnicianRole()) {
			this.addLocation(this.filteredLocations[0].name, this.filteredLocations[0].location_id, {target: {checked: true}});
		}

		this.addDeviceToList();
	}

	addDeviceToList() {
		if (this.selectedLocations == 0 && this.selectedGroups == 0) {
			this.listOfdevice.splice(0);
			this.deviceList.subscribe((x) => (this.listOfdevice = x));

			return;
		}

		if (this.selectedLocations.length > 0) {
			this.listOfdevice.splice(0);
			this.selectedLocations.forEach((l) => {
				let a = this.deviceList.subscribe((d) => {
					d.filter((dd) => dd.location_id == l.id)
						.filter((dd) => this.listOfdevice.map((a) => a.device_id).indexOf(dd.device_id) < 0)
						.map((ddd) => {
							this.listOfdevice.push(ddd);
						});
					a.unsubscribe();
				});
			});
			return;
		}

		this.listOfdevice.splice(0);

		this.selectedGroups.forEach((gp) => {
			let a = this.deviceList.subscribe((d) => {
				d.filter((dd) => dd.group_id == gp.id)
					.filter((dd) => this.listOfdevice.map((a) => a.device_id).indexOf(dd.device_id) < 0)
					.map((ddd) => {
						this.listOfdevice.push(ddd);
					});
				a.unsubscribe();
			});
		});
	}

	removeGroup(id) {
		let el = this.elRef.nativeElement.querySelector('.grp_' + id);
		if (el) el.checked = false;
		this.selectedGroups.forEach((item, index) => {
			if (id == item.id) {
				this.selectedGroups.splice(index, 1);
				// this.getDevicesByLocation();
			}
		});
	}

	isGroupSelected(id) {
		let index = this.selectedGroups.map((x) => x.id).indexOf(id);
		return index < 0 ? false : true;
	}

	addDevice(name, id, e) {
		let index = this.selectedDevices.map((x) => x.id).indexOf(id);
		if (e.target.checked && index < 0) {
			this.selectedDevices.push({ name: name, id: id });
			// this.getDevicesByLocation();
		} else {
			this.removeDevices(id);
		}
	}

	removeDevices(id) {
		let el = this.elRef.nativeElement.querySelector('.device_' + id);
		if (el) el.checked = false;
		this.selectedDevices.forEach((item, index) => {
			if (id == item.id) {
				this.selectedDevices.splice(index, 1);
				// this.getDevicesByLocation();
			}
		});
	}

	isDeviceSelected(id) {
		let index = this.selectedDevices.map((x) => x.id).indexOf(id);
		return index < 0 ? false : true;
	}

	onClickOutside(event: Object) {
		if (event && event['value'] === true) {
			this.hideFilters();
		}
	}

	hideFilters() {
		this.showLocationFilter = false;
		this.showGroupFilter = false;
		this.showDeviceFilter = false;
	}

	showLocations() {
		this.hideFilters();
		this.showLocationFilter = true;
	}

	showGroups() {
		this.hideFilters();
		this.showGroupFilter = true;
	}

	showDevices() {
		this.hideFilters();
		this.showDeviceFilter = true;
	}

	async measureAll() {
		console.log('Start measure all');
		this.isFilterVisible = false;
		localStorage.removeItem('measuring');

		await this.devices
			.subscribe((x) => {
				this.measureAllDevices = [];
				x.map((y) => {
					y.islocked !== 1 &&
						y.type === 'smu344n' &&
						this.measureAllDevices.push({ device_id: y.device_id, smu_timeStamp: y.timestamp, newTimeStamp: null });
				});
			});
		if(this.measureAllDevices.length === 0){
			console.log(`Not measuring`);
			return;
		}
		console.log(`Measuring, ${this.measureAllDevices.length} devices`);
		let list = this.measureAllDevices.map((x) => {
			console.log({ x });
			return x.device_id;
		});
		this.devicesService.measureAll(list.toString()).subscribe((results) => {
			localStorage.setItem('measuring', JSON.stringify(this.measureAllDevices));

		console.log("results", results);

			this.tableViewComponent?.refreshAll(results);
		});
		return false;
	}
}
