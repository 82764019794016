import { Component, OnInit, OnDestroy, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { Permissions } from '../../model/permissions';
import { Device } from '../../model/device';
import { DevicesService } from '../../services/devices.service';
import { HighchartExampleComponent } from '../../highchart-example/highchart-example.component';
import { TableViewComponent } from '../table-view/table-view.component';
import * as moment from 'moment';

@Component({
	selector: 'app-table-view-summary',
	templateUrl: './table-view-summary.component.html',
	styleUrls: ['./table-view-summary.component.css'],
})
export class TableViewSummaryComponent implements OnInit, OnDestroy {
	permissions = new Permissions();
	@ViewChild(HighchartExampleComponent) highchartExampleComponent: HighchartExampleComponent;
	@Output() updateParentDevice: EventEmitter<Device> = new EventEmitter();

	@Input() device: Device;
	@Input() open: any;
	@Input() model: any;
	public hasError: boolean;
	public message: string = '';
	public device_id: number;
	public interval: any;
	public statusTime;
	public measurement$: Observable<string>;
	public measurebtn = 'Measure';
	public suspend_resume = 'Suspend Measurement';
	public measurement_error_msg = 'Unable to take measurement. Please make sure the device is connected';

	constructor(private devicesService: DevicesService, private activeRoute: ActivatedRoute) {}

	ngOnInit() {
		console.log("TABLE VIEW SUMMARY SET INERVAL");
		this.device_id = this.device.device_id;

		this.changeSuspendBtnLbl();

		
		this.startIntevalUpdate();

		this.devicesService.deviceTime.subscribe((t) => {
			t.filter((tt) => tt.device_id === this.device_id).map((tt) => (this.statusTime = tt.deviceTimestamp));
		});
	}

	takeSmuMeasurement(did) {
		this.measurebtn = 'Measuring...';
		this.message = '';
		this.measurement$ = this.devicesService.takeMeasurement(did, this.device.timestamp);
		this.measurement$.subscribe(
			(x) => {
				if (x !== 'success') {
					this.measurebtn = 'Measure';
					this.hasError = true;
					this.message = this.measurement_error_msg;
					console.log(x);
					// let int = setInterval((x)=>{ clearInterval(int); this.hasError = false,this.message='';},10000)
				} else {
					this.refreshData();
				}
			},
			() => {
				this.measurebtn = 'Measure';
				this.hasError = true;
				this.message = this.measurement_error_msg;
				// let int = setInterval((x)=>{ clearInterval(int); this.hasError = false;this.message='';},10000)
			}
		);
	}

	loadChart(did, open) {
		if (open) {
			// this.highchartExampleComponent.updateSearch(did);
		}
	}

	refreshData() {
		let counter = 0;
		let timer = setInterval(() => {
			console.log("counter", counter, 'tries', this.getNumberOfTries());
			if (counter > this.getNumberOfTries()) {
				clearInterval(timer);
				this.measurebtn = 'Measure';
				this.hasError = true;
				this.message = `It is taking too long to update ${this.device.device_name}. Try again or contact administrator.`;
			}
			counter++;
			// console.log({ counter }, { try: this.getNumberOfTries() });
			this.devicesService.getDeviceStatusTime(this.device_id);
			let _tmp = JSON.parse(localStorage.getItem('measuring'));
			if (_tmp) {
				// this.measurebtn = 'Measuring...';
				_tmp.forEach((x, i) => {
					if (x.device_id == this.device_id && Number(x.smu_timeStamp) < Number(x.newTimeStamp)) {
						this.updateDevice();
						clearInterval(timer);

						_tmp.splice(i, 1);
						localStorage.setItem('measuring', JSON.stringify(_tmp));
						this.hasError = false;
						this.message = `The information for ${this.device.device_name} has been updated successfully.`;
						this.measurebtn = 'Measure';
						// let int = setInterval((x)=>{ clearInterval(int); this.hasError = false,this.message=''},30000)
					}
					// console.log('time diff', counter, x.device_id, x.smu_timeStamp, x.newTimeStamp, x.smu_timeStamp - x.newTimeStamp);
				});
			}
		}, 3000);
	}

	startIntevalUpdate() {
		this.interval = setInterval(() => {
			// console.log('table - view - summary - time', this.device_id, this.statusTime,this.device.getLatestDeviceTime(), this.statusTime - this.device.getLatestDeviceTime())
			if (this.device.getLatestDeviceTime() && this.statusTime > this.device.getLatestDeviceTime()) {
				this.updateDevice();
				// let newDeviceValue = this.devicesService.returnDeviceByID(this.device_id);
			}
		}, 5000);
	}

	updateDevice() {
		let newDeviceValue = this.devicesService.returnDeviceByID(this.device.device_id);

		newDeviceValue.subscribe((x) => {
			this.devicesService.devices.subscribe((devices) => {
				devices
					.filter((d) => d.device_id === this.device.device_id)
					.map((d) => {
						// d = this.device;
						this.device = Object.assign(d, x[0]);
						if (this.open.open && this.open.open == true && typeof x[0] !== 'undefined') {
							let d = new Date();
							// this.highchartExampleComponent.model.endDate = new Date(d);
							this.highchartExampleComponent.model.endDate = {
                				year: new Date(d).getFullYear(),
                				month: new Date(d).getMonth(),
								day: new Date(d).getDay(),
								hour:new Date(d).getHours(),
								minute:new Date(d).getMinutes(),
								isoDate: moment(d).toISOString(),
								displayDate: moment(d).format('MM/DD/YYYY HH:mm'),
								
              				};
							this.highchartExampleComponent.add(x[0]);
						}
					});
			});
		});
	}

	clearMessage() {
		this.message = '';
		this.hasError = null;
	}

	ngOnDestroy() {
		if (this.interval) {
			clearInterval(this.interval);
		}
	}

	getNumberOfTries(): number {
		let tries = 10;
		if (this.device.linear_uom.trim().toUpperCase() === 'F') {
			tries = this.device.height * 3;
		} else {
			tries = this.device.height * 3.28084 * 3;
		}
		tries = tries >= 10 ? Math.trunc(tries) : 10;
		return tries;
	}

	suspendMeasurement() {
		let action = '';
		this.hasError = false;
		this.message = '';
		const islocked = this.device.islocked === 1 ? 0 : 1;
		this.devicesService.suspendMeasurement(this.device_id, islocked).subscribe(
			(res) => {
				const data = res.data;
				if (data.status === 'error') {
					let action = this.device.islocked === 1 ? 'resume' : 'suspend';
					this.hasError = true;
					this.message = this.message = `Unable to ${action} measurement`;
				} else {
					let action = this.device.islocked === 1 ? 'resumed' : 'suspended';
					this.hasError = false;
					this.message = this.message = `Measurement has been ${action}`;
					this.device.islocked = islocked;
					this.changeSuspendBtnLbl();
				}
			},
			(err) => {
				action = this.device.islocked === 1 ? 'resume' : 'suspend';
				this.hasError = true;
				this.message = `Unable to ${action} measurement`;
			}
		);
	}

	changeSuspendBtnLbl() {
		this.suspend_resume = 'Suspend Measurement';
		if (this.device.islocked === 1) {
			this.suspend_resume = 'Resume Measurement';
		}
	}
}
