<div class="row">
  <div id="silo-container-bk" class="col-2 px-0 mr-4">
    <div id="silo-level">
      <div id="percentFull" [ngStyle]="{ height: 100 - device.getPercent() + '%' }"></div>
    </div>
    <div id="level-marker">
      <div style="height: 12.5%; border-top: 1px solid #000; width: 75%">&nbsp;</div>
      <div style="height: 12.5%; border-top: 1px solid #000; width: 20%; margin-left: 25%">&nbsp;</div>
      <div style="height: 12.5%; border-top: 1px solid #000; width: 75%">&nbsp;</div>
      <div style="height: 12.5%; border-top: 1px solid #000; width: 20%; margin-left: 25%">&nbsp;</div>
      <div style="height: 12.5%; border-top: 1px solid #000; width: 170%; margin-left: -50%">&nbsp;</div>
      <div style="height: 12.5%; border-top: 1px solid #000; width: 20%; margin-left: 25%">&nbsp;</div>
      <div style="height: 12.5%; border-top: 1px solid #000; width: 75%">&nbsp;</div>
      <div style="height: 12.5%; border-top: 1px solid #000; width: 20%; margin-left: 25%">&nbsp;</div>
    </div>
  </div>

  <div class="col-9 pb-1" id="detail">
    <div class="row">
      <div class="col-5 px-1">Location:</div>
      <div class="col-7 pl-2">{{ device.location_name }}</div>
    </div>
    <div class="row">
      <div class="col-5 px-1">Silo:</div>
      <div class="col-7 pl-2 text-nowrap pl-0">{{ device.device_name }}</div>
    </div>
    <div class="row">
      <div class="col-5 px-1">Content:</div>
      <div class="col-7 pl-2">{{ device.material }}</div>
    </div>
    <div class="row">
      <div class="col-5 px-1">Measurement:</div>
      <div class="col-7 pl-2">{{ device.getMeasurement() }} ({{ device.default_display_label }})</div>
    </div>
    <div class="row">
      <div class="col-5 px-1">Time/Date:</div>
      <div class="col-7 pl-2 text-nowrap pl-0">
        <ng-container *ngIf="device.timestamp">
          {{ device.timestamp | date: 'shortTime':'' + device.timezone }}, {{ device.timestamp | date: 'shortDate' }}
        </ng-container>
      </div>
    </div>
    <div class="row">
      <div class="col-5 px-1">Level Alarm:</div>
      <div class="col-7 pl-2 text-nowrap pl-0">{{ device.getAlert() }}</div>
    </div>
    @if (device.device_type_code && device.device_type_code.toLowerCase() !== 'bm100') {
      <div class="row">
        <div class="col-5 px-1">System Alarm:</div>
        <div
          class="col-7 pl-2 text-nowrap pl-0"
				[ngClass]="{
					'font-weight-bold': device.islocked === 1,
					'red-text': device.getStatus() !== 'A-OK' && device.getStatus() !== 'Suspended'
				}"
          >
          {{ device.getStatus() }}&nbsp;
        </div>
      </div>
    }
    @if (device.viewSignal()) {
      <div class="row">
        <div class="col-5 px-1">Signal Strength:</div>
        <div class="col-7 pl-2 text-nowrap pl-0">{{ device.signal_strength }} db</div>
      </div>
    }
    @if ((device.device_type_code && device.device_type_code.toLowerCase() === 'bm100') || device.device_type_code === null) {
      <div class="row">&nbsp;</div>
    }
  </div>
</div>
